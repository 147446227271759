import { navigate, useIntl } from "gatsby-plugin-intl";
import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import * as UI from "../components/UI";
import { participar, setParticiparData } from "../redux/app/actions";
import l from "lodash";
import { Helmet } from "react-helmet";
import close from "../assets/gfx/close.svg";
import { Modal } from "semantic-ui-react";
import styled from "styled-components";

const ModalBody = styled.div`
	background: #0b1926;
	padding: 80px;
	position: relative;
`;

const ModalClose = styled.button`
	position: absolute;
	top: 15px;
	right: 20px;
	cursor: pointer;
`;

const ModalTitle = styled.div`
	color: #c6b27b;
	font-size: 16px;
	margin-bottom: 25px;
`;

const ModalText = styled.div`
	p {
		margin-bottom: 20px;
	}
`;

const ParticipatePage = ({ boot, bootLoaded, usuario, codigo, errors, s, participar, setParticiparData }) => {
	const intl = useIntl();

	const [canRender, setCanRender] = React.useState(false);

	React.useEffect(() => {
		if (usuario.estanco) setCanRender(true);
		else navigate("/");
	}, []);

	React.useEffect(() => {
		console.log(errors);
	}, [errors]);

	const go = () => {
		participar(intl.locale, {
			estanco: usuario.estanco,
			codigo,
		});
	};

	const setCodigo = (c) => {
		setParticiparData("codigo", c);
	};

	const [tooSoon, setTooSoon] = useState(false);

	React.useEffect(() => {
		setTooSoon(l.includes(errors, "too-soon"));
	}, [errors]);

	return (
		<UI.Layout graficos="default" background="1">
			{bootLoaded && canRender && (
				<>
					<Helmet>
						<title data-string="participarHTMLTitle">{s.participarHTMLTitle}</title>
					</Helmet>
					<UI.Separador />
					<UI.Parrafo data-string="participarReadyToWin">
						<strong>{s.participarReadyToWin}</strong>
					</UI.Parrafo>
					<UI.TextInput
						data-string="participarCodigoLabel, participarWrongCodigoLabel, participarUsedCodigoLabel"
						label={
							l.includes(errors, "wrong-codigo")
								? s.participarWrongCodigoLabel
								: l.includes(errors, "missing-codigo")
								? s.participarMissingCodigoLabel
								: l.includes(errors, "used-codigo")
								? s.participarUsedCodigoLabel
								: s.participarCodigoLabel
						}
						theme={
							l.includes(errors, "wrong-codigo") ||
							l.includes(errors, "missing-codigo") ||
							l.includes(errors, "used-codigo")
								? "error"
								: "normal"
						}
						onChange={(e) => setCodigo(e.target.value)}
					/>
					<UI.Boton onClick={go} data-string="participarValidar">
						{s.participarValidar}
					</UI.Boton>
					<Modal basic open={tooSoon} size="small">
						<Modal.Content>
							<ModalBody>
								<ModalClose onClick={() => setTooSoon(false)}>
									<img src={close} />
								</ModalClose>
								<ModalTitle data-string="participarDemasiadoProntoTitle">
									{s.participarDemasiadoProntoTitle}
								</ModalTitle>
								<ModalText
									data-string="participarDemasiadoProntoTexto"
									dangerouslySetInnerHTML={{ __html: s.participarDemasiadoProntoTexto }}
								/>
							</ModalBody>
						</Modal.Content>
					</Modal>
				</>
			)}
		</UI.Layout>
	);
};

const mapStateToProps = (state) => {
	const { boot, bootLoaded, usuario, codigo } = state.app;
	const { participar: errors } = state.app.errors;
	const { strings: s } = state.app.boot;
	return {
		boot,
		bootLoaded,
		usuario,
		codigo,
		errors,
		s,
	};
};

const mapDispatchToProps = {
	participar,
	setParticiparData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipatePage);
